import { useEffect, useState } from "react";
import useScript from "../../const/useScript";

const Transform = () => {
  const [start, setStart] = useState(false);
  const [sec, setSec] = useState(10);

  useEffect(() => {
    setStart(true);
  }, []);

  useEffect(() => {
    let interval = setTimeout(() => {
      start && sec > 0 && setSec(sec - 1);
    }, 1000);

    if (sec === 0) window.location.href = "/";

    return () => clearTimeout(interval);
  }, [sec, start]);

  useScript("https://www.googletagmanager.com/gtag/js?id=AW-16810839896");
  useScript("//wcs.naver.net/wcslog.js");

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-16810839896');
  gtag('event', 'conversion', {'send_to': 'AW-16810839896/NmGRCOW12vMZENiehNA-'});`;

    const wcs = document.createElement("script");
    wcs.async = true;
    wcs.innerHTML = `if(!wcs_add) var wcs_add = {};
    if(window.wcs){
    wcs_add['wa'] = 's_6862f599f90';
    var _conv = {};
    	_conv.type = 'lead';
    wcs.trans(_conv);
    }`;

    document.body.appendChild(script);
    document.body.appendChild(wcs);
    return () => {
      document.body.removeChild(script);
      document.body.removeChild(wcs);
    };
  }, []);
  return (
    <div className=" relative w-full h-full">
      <div className=" relative w-full h-full px-[calc((100%-var(--container))/2)] my-5 pt-36 pb-28 lg:pt-52">
        <div className=" relative w-full h-full mx-auto max-w-screen-sm">
          <img
            className=" relative w-full h-auto object-contain"
            src="/images/complete.svg"
            alt="complete"
          />
        </div>
        <div className=" relative w-full h-full my-5 pb-10 text-center">
          <h3>문의 접수가 완료되어 {sec}초 뒤 메인페이지로 이동합니다.</h3>
        </div>
        <div className=" relative w-fit h-fit mx-auto ">
          <button
            type="button"
            onClick={() => (window.location.href = "/")}
            className=" relative w-fit h-fit py-1 px-10 lg:py-3 lg:px-32 bg-primary text-white rounded-full hover:shadow-lg"
          >
            <h4>메인페이지로 돌아가기</h4>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Transform;
